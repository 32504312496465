import { createApp } from "vue/dist/vue.esm-bundler.js";
import placeZone from "./template/placeZone.vue";

const COLS = 5;
const ROWS = 11;
const CELL_SIZE = 60;

function canPlaceItem(grid, w, h, x, y, cols) {
   if (x + w > cols || y + h > grid.length / cols) return false;
   for (let i = y; i < y; i++) {
      for (let j = x; j < x; j++) {
         if (grid[i * cols + j] !== 0) {
            return false;
         }
      }
   }
   return true;
}
function placeItem(grid, w, h, x, y, cols) {
   const occupiedCells = [];
   for (let i = y; i < y + h; i++) {
      for (let j = x; j < x + w; j++) {
         grid[i * cols + j] = 1;
         occupiedCells.push(i * cols + j);
      }
   }
   return occupiedCells;
}

const app = createApp({
   data() {
      return {
         cells: {},
         items: [
            { id: 1, width: 2, height: 2, img: require("../img/2x2.png"), cells: [0, 1, 5, 6], set: "left" },
            { id: 2, width: 1, height: 2, img: require("../img/2x2.png"), cells: [8, 8, 13, 13], set: "left" },
            { id: 3, width: 1, height: 1, img: require("../img/2x2.png"), cells: [15], set: "left" },
         ],
         ignoreSize: false,
         inSafeZone: true,
         currentGroup: null,
         currentCells: null,
         currentItem: null,
         currentItemOutside: false,
         ghost: null,
      };
   },
   computed: {
      itemsLeft() {
         return this.items.filter((e) => e.set === "left");
      },
      itemsMiddle() {
         return this.items.filter((e) => e.set === "middle");
      },
      itemsRight() {
         return this.items.filter((e) => e.set === "right");
      },
      currentGroupName() {
         return this.currentGroup?.dataset?.group;
      },
      // ignoreSize() {
      //    return this.inSafeZone && this.currentGroup?.hasAttribute("data-ignore-size");
      // },
   },
   methods: {
      addItem(width = 2, height = 2) {
         const grid = new Array(ROWS * COLS).fill(0);
         let itemPlaced = false;
         let occupiedCells = [];
         for (let y = 0; y < ROWS && !itemPlaced; y++) {
            for (let x = 0; x < COLS && !itemPlaced; x++) {
               if (canPlaceItem(grid, width, height, x, y, COLS)) {
                  occupiedCells = placeItem(grid, width, height, x, y, COLS);
                  itemPlaced = occupiedCells && !this.itemsLeft.some((item) => occupiedCells.some((c) => item.cells.includes(c)));
               }
            }
         }
         if (itemPlaced && occupiedCells) {
            this.items.push({ id: this.items.at(-1).id + 1, width, height, img: require("../img/2x2.png"), cells: occupiedCells, set: "left" });
         }
      },
      resetHoveredCells() {
         this.cells[this.currentGroupName].forEach((c) => (c.hovered = false));
      },
      getItemBySet(set, name) {
         return this.items.find((e) => e.set === set && e.cells === name);
      },
      onDrop() {
         const { currentItem, currentGroupName, currentCells, cells, ghost } = this;
         if (!currentItem) return;

         if (this.currentGroupName === "middle") {
            if (currentCells && currentGroupName) {
               currentItem.set = currentGroupName;
               currentItem.cells = currentCells;
            }
         } else {
            const hoveredCells = cells[currentGroupName].filter((c) => c.hovered).map((c) => c.i);
            const itemFits = this.itemFitsIngroup(hoveredCells);
            if (itemFits && hoveredCells.length) {
               currentItem.set = currentGroupName;
               currentItem.cells = hoveredCells;
            }
         }
         this.resetHoveredCells();

         ghost?.remove();

         if (ghost.hasAttribute("data-remove")) {
            this.items = this.items.filter((item) => item.id !== this.currentItem.id);
            this.onItemRemoved(this.currentItem);
         } else {
            this.onItemMoved(this.currentItem);
         }

         this.currentCells = null;
         this.offsetItem = null;
         this.currentItem = null;
      },
      itemFitsIngroup(cells) {
         const indices = new Set(cells);
         const maxIndex = ROWS * COLS - 1;

         if ([...indices].some((i) => i < 0 || i > maxIndex || cells[0] % COLS > cells[1] % COLS)) {
            return false;
         }

         const minRow = Math.min(...cells.map((i) => Math.floor(i / COLS)));
         const maxRow = Math.max(...cells.map((i) => Math.floor(i / COLS)));
         const minCol = Math.min(...cells.map((i) => i % COLS));
         const maxCol = Math.max(...cells.map((i) => i % COLS));
         if (minRow < 0 || maxRow >= ROWS || minCol < 0 || maxCol >= COLS) {
            return false;
         }

         // Check if any cell overlaps with another item
         const indicesWithItem = new Set(this.items.filter((item) => item.id !== this.currentItem.id && item.set === this.currentGroupName).flatMap((item) => item.cells));
         if ([...indices].some((item) => indicesWithItem.has(item))) {
            return false;
         }

         return true;
      },
      onDragstart(event, item) {
         this.currentGroup = event.target.closest("[data-group]");
         this.dragEvent = event;
         this.currentItem = item;
         this.offsetItem = {
            cells: [Math.min(Math.ceil(event.offsetX / CELL_SIZE), item.width) - 1, Math.min(Math.ceil(event.offsetY / CELL_SIZE), item.height) - 1],
            percent: [event.offsetX / event.target.offsetWidth, event.offsetY / event.target.offsetHeight],
         };

         this.createGhost();
         this.onDrag(event);

         document.addEventListener("mousemove", this.onDrag);
         document.addEventListener(
            "mouseup",
            () => {
               this.onDrop();
               document.removeEventListener("mousemove", this.onDrag);
            },
            { once: true }
         );
      },
      onDrag(event) {
         const item = this.currentItem;
         if (!item) return;
         const { currentGroup, ghost, dragEvent, offsetItem, currentGroupName } = this;
         // Get the mouse position relative to the group container
         const rect = currentGroup.getBoundingClientRect();
         const x = event.clientX - rect.left;
         const y = event.clientY - rect.top;

         const ignoreSize = (this.ignoreSize = event.target.closest("[data-ignore-size]"));

         ghost.toggleAttribute("data-ignore-size", ignoreSize);

         if (ignoreSize) {
            ghost.style.transform = `translate3d(${event.pageX - CELL_SIZE / 2}px,${event.pageY - CELL_SIZE / 2}px,0)`;
         } else {
            ghost.style.transform = `translate3d(${event.pageX - ghost.offsetWidth * offsetItem.percent[0]}px,${event.pageY - ghost.offsetHeight * offsetItem.percent[1]}px,0)`;
         }

         const left = Math.floor(x / CELL_SIZE) - offsetItem.cells[0];
         const top = Math.floor(y / CELL_SIZE) - offsetItem.cells[1];
         this.inSafeZone = event.target.closest("[data-safe-zone]");
         ghost.toggleAttribute("data-remove", !this.inSafeZone);
         if (left >= COLS || left <= -item.width || top <= -item.height || top > ROWS - 1) {
            this.currentItemOutside = true;
         } else {
            this.currentItemOutside = false;
         }
         let cells = [];

         if (!ignoreSize) {
            const right = left + item.width - 1;
            const bottom = top + item.height - 1;
            const minRow = Math.min(top, ROWS - 1);
            const maxRow = Math.min(bottom, ROWS - 1);
            const minCol = Math.min(left, COLS - 1);
            const maxCol = Math.min(right, COLS - 1);
            for (let row = minRow; row <= maxRow; row++) {
               for (let col = minCol; col <= maxCol; col++) {
                  cells.push(row * COLS + col);
               }
            }
         } else {
            cells = this.currentCells;
         }
         if (this.currentItemOutside) {
            this.resetHoveredCells();
            return;
         }

         if (!ignoreSize && (cells.length < item.width * item.height || !this.itemFitsIngroup(cells))) return;
         // Set the hovered state of each cell

         this.cells[currentGroupName].forEach((cell) => {
            cell.hovered = ignoreSize ? cell.i === this.currentItem.cells : cells.includes(cell.i);
         });
      },
      createGhost(elem = this.dragEvent.target) {
         const { offsetWidth, offsetHeight } = elem;
         const ghost = elem.cloneNode(true);
         ghost.toggleAttribute("data-ghost");
         Object.assign(ghost.style, {
            position: "fixed",
            top: 0,
            left: 0,
            width: offsetWidth + "px",
            height: offsetHeight + "px",
            pointerEvents: "none",
            opacity: 0.85,
         });

         document.getElementById("inventar").appendChild(ghost);

         if (this.ignoreSize || (!this.ignoreSize && this.currentGroupName === "middle")) {
            ghost.offsetWidth;
            ghost.style.removeProperty("width");
            ghost.style.removeProperty("height");
         }

         this.ghost = ghost;
      },
      calculateRect(item) {
         const minCell = Math.min(...item.cells);
         const left = minCell % COLS;
         const top = Math.floor(minCell / COLS);
         return { "--left": left, "--top": top, "--width": item.width, "--height": item.height };
      },
      groupEnter(e) {
         const currentGroup = e.target.closest("[data-group]");
         if (this.currentGroupName) {
            this.resetHoveredCells();
         }
         this.currentGroup = currentGroup;
      },
      placeEnter(name) {
         if (this.currentItem) {
            this.currentCells = name;
         }
      },
      onItemMoved(item) {
         console.log("moved", item);
      },
      onItemRemoved(item) {
         console.log("removed", item);
      },
   },
   mounted() {
      this.cells.left = Array.from({ length: COLS * ROWS }, (_, i) => ({ i, hovered: false }));
      this.cells.middle = [
         "place1",
         "place2",
         "place3",
         "place4",
         "place5",
         "place6",
         "place7",
         "place8",
         "place9",
         "place10",
         "place11",
         "place12",
         "place13",
         "place14",
         "place15",
         "place16",
         "place17",
         "place18",
         "place19",
      ].map((i) => ({ i, hovered: false }));
      this.cells.right = Array.from({ length: COLS * ROWS }, (_, i) => ({ i, hovered: false }));
   },
});
app.component("placeZone", placeZone);
app.mount("#inventar");
